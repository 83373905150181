export const globalMixin = {
  data: () => ({
    uploadsUrl:
      process.env.NODE_ENV === "production"
        ? "/api/uploads"
        : "http://127.0.0.1:8000/uploads",
    modalOpen: false,
    filterKey: null,
    publicPath: process.env.BASE_URL,
    permissionsPath: null,
    paymentMethods: ["Cash", "Momo", "Cheque", "Cards"],
  }),
  computed: {
    isUserLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userPermissions() {
      if (this.$store.getters.user) {
        let role = this.$store.getters.user.role;
        if (role) return role.permissions;
      }
      return {};
    },
    appSettings() {
      let settings = this.$store.state.settings || {};
      return settings.result || {};
    },
    loggedUser() {
      return this.$store.getters.user || {};
    },
    userRole() {
      if (this.loggedUser.role) return this.loggedUser.role.name;
      else return null;
    },
    currentBranch() {
      return this.$store.getters.branch;
    },
  },
  methods: {
    handleLogout() {
      this.$http
        .post("auth/logout", this.$helper.generateFormData({}))
        .then((response) => {
          if (response.data.status) {
            this.$store.dispatch("logout").then(() => {
              window.location.replace("/");
            });
          }
        });
    },
    toggleModal() {
      this.$helper.toggleModalOpen();
      this.modalOpen = !this.modalOpen;
    },
    handleEntityImageModify(args = {}) {
      this.$store.dispatch("SetEntityPostData", args).then(() => {
        document.getElementById("global-upload").click();
      });
    },
    canView(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return this.userRole == "ADMINISTRATOR" || access[0] == 1;
    },
    canCreate(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return this.userRole == "ADMINISTRATOR" || access[1] == 1;
    },
    canEdit(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return this.userRole == "ADMINISTRATOR" || access[2] == 1;
    },
    canDelete(path) {
      const access =
        this.$helper.getKeyValue(path, this.userPermissions) || "0000";
      return this.userRole == "ADMINISTRATOR" || access[3] == 1;
    },
    formatMoney(num) {
      return `${this.appSettings.currency} ${this.$helper.formatNumber(num)}`;
    },
  },
};
