import { createRouter, createWebHistory } from "vue-router";
import { frontOffice } from "./front-office";
import { backOffice } from "./back-office";
import store from "@/store";
const publicRoutes = [
  {
    path: "/:pathMatch(.*)*",
    name: "error404",
    component: () =>
      import(/* webpackChunkName: "mainChuck" */ "../index/views/404.vue"),
    meta: {
      title: "Page Not Found",
    },
  },
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "mainChuck" */ "../index/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...publicRoutes, ...frontOffice, ...backOffice],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/");
  } else if (to.matched.some((record) => record.meta.requireFrontOfficeAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/front-office/");
  } else {
    next();
  }
});

const DEFAULT_TITLE = "TAME POS";
router.afterEach((to) => {
  document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
  if (store.state.drawerOpen) store.state.drawerOpen = false;
  [...document.querySelectorAll("div.dropdown-menu")].forEach((elt) => {
    elt.classList.remove("show");
  });
});

export default router;
