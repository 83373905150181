import { createStore } from "vuex";
export default createStore({
  state: {
    timeZone: localStorage.getItem("_tz") || "Africa/Kigali",
    today:
      localStorage.getItem("today") ||
      new Date()
        .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
        .slice(0, 10),
    token: localStorage.getItem("token") || "",
    branch: JSON.parse(localStorage.getItem("_branch")) || {},
    user: {},
    isLoading: false,
    requestFlag: null,
    isCorrectSystemDay: false,
    drawerOpen: true,
    hasActiveModal: false,
    flashMessage: {},
    branches: [],
    settings: {
      site_address: null,
      site_name: null,
      currency: null,
      contact_one: null,
      app_phone: null,
      app_email: null,
      app_tin: null,
    },
    notifications: {
      requisitions: 0,
      purchase_orders: 0,
      suppliers_payments: 0,
      pending_orders: 0,
    },
  },

  mutations: {
    REQUEST_RESOLVED(state) {
      state.isLoading = false;
      state.requestFlag = null;
      if (state.hasActiveModal) {
        state.hasActiveModal = false;
        document.querySelector("#alert-container").remove();
      }
    },
    REGISTER_CONFIRM_MODAL(state) {
      state.requestFlag = "HANDLING_ALERT_ACTION";
      state.hasActiveModal = true;
    },
    SET_APP_SETTINGS(state, settings) {
      state.settings = settings;
      localStorage.setItem("_tz", settings.timezone);
      localStorage.setItem("today", settings.today);
    },
    SET_LOCKED_BLANCH(state, branch) {
      if (branch != null)
        localStorage.setItem("_branch", JSON.stringify(branch));
      else localStorage.removeItem("_branch");
      state.branch = branch;
    },
    SET_REQUEST_FLAG(state, flag) {
      state.requestFlag = flag;
    },
    SET_FLASH_MESSAGE(state, message) {
      state.flashMessage = message;
      setTimeout(() => {
        state.flashMessage = {};
      }, 5000);
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    SET_CURRENT_USER(state, user) {
      state.user = user;
    },
    SET_BRANCHES(state, branches) {
      state.branches = branches;
    },
    SET_MENU_NOTIFICATIONS(state, records) {
      state.notifications = { ...state.notifications, ...records };
    },
    REDUCE_NOTIFICATIONS(state, obj) {
      state.notifications[obj.key] -= obj.total || 1;
    },
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("_branch");
        localStorage.removeItem("token");
        localStorage.clear();
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
    branch: (state) => state.branch,
    requestFlag: (state) => state.requestFlag,
  },
});
