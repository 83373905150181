export const frontOffice = [
  {
    path: "/front-office",
    name: "FrontOfficeLogin",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../front-office/views/Login.vue"
      ),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/front-office/orders",
    name: "WaiterOrders",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../front-office/views/WaiterOrders.vue"
      ),
    meta: {
      title: "Waiter Orders",
      requireFrontOfficeAuth: true,
    },
  },
  {
    path: "/front-office/orders/:action/:reference?",
    name: "OrdersCreator",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../front-office/views/OrderCreator.vue"
      ),
    meta: {
      title: "Order Creator",
      requireFrontOfficeAuth: true,
    },
  },
  {
    path: "/front-office/kitchen/orders",
    name: "KitchenOrders",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../front-office/views/DestinationOrders.vue"
      ),
    meta: {
      title: "Kitchen Orders",
      requireFrontOfficeAuth: true,
    },
  },
  {
    path: "/front-office/bar/orders",
    name: "BarOrders",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../front-office/views/DestinationOrders.vue"
      ),
    meta: {
      title: "Bar Orders",
      requireFrontOfficeAuth: true,
    },
  },
];
