export const backOffice = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Dashboard/Index.vue"
      ),
    meta: {
      title: "Dashboard",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/items",
    name: "Items",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Items/Index.vue"
      ),
    meta: {
      title: "Food Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/items/:action/:reference?",
    name: "CreateOrEditItem",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Items/ItemCreator.vue"
      ),
    meta: {
      title: "Create Menu Item",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches",
    name: "Branches",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Branches/Index.vue"
      ),
    meta: {
      title: "All Branches",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings/Tables",
    name: "Tables",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Settings/Tables.vue"
      ),
    meta: {
      title: "Tables Settings",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "mainChuck" */ "../index/views/Orders.vue"),
    meta: {
      title: "Orders Listing",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/users",
    name: "Users",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Lead/Users.vue"
      ),
    meta: {
      title: "All Users",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/clients",
    name: "Clients",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Lead/Clients.vue"
      ),
    meta: {
      title: "All Clients",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/sales",
    name: "Sales",
    component: () =>
      import(/* webpackChunkName: "mainChuck" */ "../index/views/Sales.vue"),
    meta: {
      title: "Sales Listing",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports",
    name: "ReportsIndex",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/Index.vue"
      ),
    meta: {
      title: "Reports",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/sales",
    name: "SalesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Sales Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/orders",
    name: "OrdersReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/OrdersReport.vue"
      ),
    meta: {
      title: "Orders Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/invoices",
    name: "InvoicesReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/InvoicesReport.vue"
      ),
    meta: {
      title: "Invoices Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/details",
    name: "DetailsReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/DetailsReport.vue"
      ),
    meta: {
      title: "Details Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/credit-post",
    name: "CreditPostReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Credit Post Report",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/reports/waiters-balance",
    name: "WaitersBalanceReport",
    component: () =>
      import(
        /* webpackChunkName: "mainChuck" */ "../index/views/Reports/WaitersBalanceReport.vue"
      ),
    meta: {
      title: "Waiters Balance Report",
      requiresAdminAuth: true,
    },
  },
];
